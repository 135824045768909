@import 'styles/utils/mixins';
@import 'styles/utils/colors';
@import 'styles/utils/sizes';
@import 'styles/utils/theme/animations';

.container {
  @include flex(row, space-between, center);

  overflow: hidden;
  gap: 32px;
  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollingEl {
  @include flex(row, space-between, center);

  gap: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  animation: image-scroll 32s linear infinite;
}

.photo {
  position: relative;
  flex-shrink: 0;
  border-radius: 24px;
  overflow: hidden;

  img {
    object-fit: cover !important;
  }

  &-1,
  &-3,
  &-5 {
    width: 400px;
    height: 512px;

    @include below-tablet {
      width: 305px;
      height: 389px;
    }

    @include phone {
      width: 269px;
      height: 343px;
    }
  }

  &-2,
  &-6 {
    width: 700px;
    height: 400px;

    @include below-tablet {
      width: 533px;
      height: 305px;
    }

    @include phone {
      width: 470px;
      height: 269px;
    }
  }

  &-4 {
    width: 400px;
    height: 400px;

    @include below-tablet {
      width: 269px;
      height: 269px;
    }
  }
}
