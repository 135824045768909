@import 'styles/utils/variables';

@keyframes blinking {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes swing {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animateborder {
  0% {
    border-radius: $max-radius;
    transform: scale(0);
    opacity: 0;
  }

  2% {
    opacity: 0;
  }

  18% {
    border-radius: $initial-radius;
    transform: scale(1);
    opacity: 1;
  }

  90% {
    border-radius: $initial-radius;
    transform: scale(1);
  }
}

@keyframes animateborder-overlay {
  0% {
    border-radius: calc(#{$max-radius} - 15px);
    transform: scale(0);
    opacity: 0;
  }

  2% {
    opacity: 0;
  }

  18% {
    border-radius: calc(#{$initial-radius} - 15px);
    transform: scale(1);
    opacity: 1;
  }

  90% {
    border-radius: calc(#{$initial-radius} - 15px);
    transform: scale(1);
  }
}

@keyframes animatecircle {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  5% {
    transform: scale(0);
    opacity: 0;
  }

  10% {
    transform: scale(1.3);
    opacity: 1;
  }

  15% {
    transform: scale(1);
  }

  95% {
    transform: scale(1);
    background-color: transparent;
  }

  97% {
    transform: scale(1.2);
    background-color: $turbo-100;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animatedot {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animStar {
  0% {
    transform: translate(0, -200px);
  }

  100% {
    transform: translate(-300px, 100px);
  }
}

@keyframes hideText {
  0% {
    width: 131px;
  }

  100% {
    width: 50px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes outOfTransparencyDesktop {
  0% {
    opacity: 0;
    transform: rotate(-30deg) scale(0);
  }

  100% {
    opacity: 1;
    transform: rotate(-30deg) scale(0.4);
  }
}

@keyframes outOfTransparencyMobile {
  0% {
    opacity: 0;
    transform: rotate(-30deg) scale(0);
  }

  100% {
    opacity: 1;
    transform: rotate(-30deg) scale(0.35);
  }
}

@keyframes appearance {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes appearanceFromBelow {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(100px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes flicker {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes vibrate1 {
  0% {
    transform: translate3d(0, 0, 0);
  }

  10% {
    transform: translate(-1px, -1.5px);
  }

  20% {
    transform: translate(-0.5px, 0);
  }

  30% {
    transform: translate(0.5px, 1.5px);
  }

  40% {
    transform: translate(0.5px, -0.5px);
  }

  50% {
    transform: translate3d(-0.05rem, 0, 0);
  }

  60% {
    transform: translate(-0.5px, 0.5px);
  }

  70% {
    transform: translate(0.5px, 1px);
  }

  80% {
    transform: translate(-1px, -1px);
  }

  90% {
    transform: translate(1px, 2px);
  }

  100% {
    transform: translate3d(0.05rem, 0, 0);
  }
}

@keyframes rocket {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(3.5px, -3.5px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes burgerItem1 {
  0% {
    top: $burger-item-1;
  }

  50% {
    top: $burger-item-2;
    transform: rotate(0);
  }

  100% {
    top: $burger-item-2;
    transform: rotate(45deg);
  }
}

@keyframes burgerItem2 {
  100% {
    opacity: 0;
  }
}

@keyframes burgerItem3 {
  0% {
    top: $burger-item-2;
  }

  50% {
    top: $burger-item-2;
    transform: rotate(0);
  }

  100% {
    top: $burger-item-2;
    transform: rotate(-45deg);
  }
}

@keyframes burgerItem1Reverse {
  0% {
    top: $burger-item-2;
    transform: rotate(45deg);
  }

  50% {
    top: $burger-item-2;
    transform: rotate(0);
  }

  100% {
    top: $burger-item-1;
  }
}

@keyframes burgerItem2Reverse {
  0% {
    opacity: 0;
  }
}

@keyframes burgerItem3Reverse {
  0% {
    top: $burger-item-2;
    transform: rotate(-45deg);
  }

  50% {
    top: $burger-item-2;
    transform: rotate(0);
  }

  100% {
    top: $burger-item-3;
  }
}

@keyframes arrow-down {
  0% {
    opacity: 0.4;
    transform: rotate(180deg) translateY(10px);
  }

  50% {
    transform: rotate(180deg) translateY(-10px);
    opacity: 1;
  }

  100% {
    opacity: 0.4;
    transform: rotate(180deg) translateY(10px);
  }
}

@keyframes image-scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes image-scroll-reverse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes stickerbox-pointer-move {
  85% {
    transform: translate(325px, 592px);
  }

  90% {
    transform: translate(325px, 592px) scale(0.85);
  }

  100% {
    transform: translate(325px, 592px) scale(1);
  }
}

@keyframes hide-image {
  100% {
    visibility: hidden;
  }
}

@keyframes stickerbox-pointer-second {
  85% {
    transform: translate(295px, 600px);
  }

  90% {
    transform: translate(295px, 600px) scale(0.85);
  }

  100% {
    transform: translate(295px, 600px) scale(1);
  }
}

@keyframes stickerbox-pointer-third {
  85% {
    transform: translate(390px, 600px);
  }

  90% {
    transform: translate(390px, 600px) scale(0.85);
  }

  100% {
    transform: translate(390px, 600px) scale(1);
  }
}

@keyframes cedar-bounce {
  0% { transform: translateY(0); }
  50% { transform: translateY(-24px); }
  100% { transform: translateY(0); }
}
